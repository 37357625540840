import { FC } from 'react';
import { NumberLabel } from './index.style';

const std = (locale: string, fractionDigits = 2) => ({
  currency: {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
    style: 'decimal',
  },
  locale,
});

const dictionary = {
  AR: std('es-AR'),
  BE: std('fr-BE'),
  BO: std('es-BO'),
  BR: std('pt-BR'),
  CA: std('en-CA'),
  CL: std('pt-BR'), // grouped by 2 numbers, as in pt-BR
  CO: std('es-CO', 1), // grouped by 2 numbers, as in pt-BR
  DE: std('de-DE'),
  DO: std('en-US'),
  EC: std('en-US'),
  ES: std('es-ES'),
  GB: std('en-GB'),
  HN: std('en-US'),
  KR: std('ko-KR', 0),
  MX: std('en-US'),
  NL: std('nl-NL'),
  PA: std('es-PA'),
  PE: std('en-US'),
  PY: std('pt-BR', 0),
  SV: std('en-US'),
  US: std('en-US'),
  UY: std('pt-BR'),
  ZA: std('en-ZA'),
};

const currency = {
  AR: '$',
  BE: '€',
  BO: 'Bs.',
  BR: 'R$',
  CA: '$',
  CL: '$',
  CO: '$',
  DE: '€',
  DO: 'RD$',
  EC: '$',
  ES: '€',
  GB: '£',
  HN: 'L',
  KR: '₩',
  MX: '$',
  NL: '€',
  PA: 'B/.',
  PE: 'S/',
  PY: 'Gs.',
  SV: '$',
  US: '$',
  UY: '$',
  ZA: 'R',
};

export type NumberFormatStyle = 'currency' | 'decimal' | 'percent' | undefined;

export const numberFormat = (
  value: number,
  locale: string,
  style: NumberFormatStyle = undefined,
  options: object = {}
) => {
  const hasDecimals = !Number.isInteger(value);
  const precision = hasDecimals ? 2 : 0;
  if (typeof Intl === 'object' && typeof Intl.NumberFormat === 'function') {
    const formattedValue = new Intl.NumberFormat(dictionary[locale].locale, {
      style,
      ...dictionary[locale][style || 'decimal'],
      ...options,
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    }).format(value);

    if (style === 'currency') {
      if (locale === 'CA') {
        return `${formattedValue} ${currency[locale]}`;
      } else if (locale === 'ZA') {
        return `${currency[locale]}${formattedValue.replace(/,/g, ' ').replace('.', ',')}`;
      } else {
        return `${currency[locale]}${formattedValue}`;
      }
    } else {
      return formattedValue;
    }
  }

  return value.toString();
};

export const getCurrencyByLocale = (locale: string) => {
  const DEFAULT_CURRENCY = '$';
  const WARNING_MESSAGE =
    'Locale is not defined or does not exist in currency object, defaulting to USD';
  if (!locale || !currency[locale]) {
    console.warn(WARNING_MESSAGE);
    return DEFAULT_CURRENCY;
  }
  return currency[locale];
};

export interface INumberDisplaySpecificProperties {
  value: number;
  style?: NumberFormatStyle;
  locale: string;
  options?: object;
  [key: string]: any;
}

export interface INumberDisplayProps {
  name?: string;
  attributes?: INumberDisplaySpecificProperties;
}

const NumberDisplay: FC<INumberDisplayProps> = ({ name, attributes }) => {
  if (!attributes) {
    return null;
  }

  const { value, locale, style, options, ...other } = attributes;

  return (
    <NumberLabel id={name} {...other}>
      {numberFormat(value, locale, style, options)}
    </NumberLabel>
  );
};

export default NumberDisplay;
