import {
  useAppHeader,
  useAuthenticationService,
  usePreferredLanguageV2,
  useUserMetadata,
} from 'admin-portal-shared-services';
import { get as getApi, post, postFormData, put as putApi } from '../../api';
import {
  IRequestFileDataProps,
  IRequestType,
  ISpreeadsheetRequestResponse,
  TDriverResponse,
  TUpdateDriverProgress,
  TUseScopeCheckResponse,
} from './DriverService.types';
/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

export const DRIVER_BASE_URL = `api/link-commercial-kpis-service/driver`;

const CONTRACT_BASE_URL = `api/link-commercial-kpis-service/contract`;

export const QUERY_KEY_TEMPLATE = 'template';

export const QUERY_KEY_UPLOAD = 'upload';

export const SPREEADSHEET_TEMPLATE_URL = `${DRIVER_BASE_URL}/${QUERY_KEY_TEMPLATE}`;

export const SPREEADSHEET_UPLOAD_URL = `${SPREEADSHEET_TEMPLATE_URL}/${QUERY_KEY_UPLOAD}`;

export const SPREADSHEETS_IRS_TEMPLATE_URL = `${CONTRACT_BASE_URL}/${QUERY_KEY_TEMPLATE}`;

export const SPREADSHEETS_IRS_CREATION_URL = `${CONTRACT_BASE_URL}/${QUERY_KEY_TEMPLATE}/${QUERY_KEY_UPLOAD}`;

export const SPREADSHEETS_AR_PROCESSING = `${DRIVER_BASE_URL}/${QUERY_KEY_TEMPLATE}/${QUERY_KEY_UPLOAD}?fileType=AR`;

const ActionType = {
  CREATE: 'create',
  UPDATE: 'update',
  NULL: null,
};

const RequestType = {
  GET: 'get',
  POST: 'post',
};

const FeatureType = {
  JBP: 'JBP',
  IRS: 'IRS',
  ESCALAS: 'ESCALAS',
};

const URL_MAP = {
  [`${RequestType.GET}_${ActionType.NULL}_${FeatureType.JBP}`]: SPREEADSHEET_TEMPLATE_URL,
  [`${RequestType.GET}_${ActionType.NULL}_${FeatureType.ESCALAS}`]: `${SPREADSHEETS_IRS_TEMPLATE_URL}/ar`,
  [`${RequestType.GET}_${ActionType.NULL}_${FeatureType.IRS}`]: SPREADSHEETS_IRS_TEMPLATE_URL,
  [`${RequestType.POST}_${ActionType.CREATE}_undefined`]: SPREADSHEETS_IRS_CREATION_URL,
  [`${RequestType.POST}_${ActionType.CREATE}_undefined_AR`]: SPREADSHEETS_AR_PROCESSING,
  [`${RequestType.POST}_${ActionType.UPDATE}_undefined`]: SPREEADSHEET_UPLOAD_URL,
  [`${RequestType.POST}_${ActionType.UPDATE}_undefined_AR`]: SPREADSHEETS_AR_PROCESSING,
};

const urlAccordingProps = ({ actionType, request, featureType, selectedCountry }: IRequestType) => {
  const key = `${request}_${actionType}_${featureType}${selectedCountry === 'AR' ? '_AR' : ''}`;
  const url = URL_MAP[key];

  if (url) {
    return url;
  } else {
    console.error('Invalid parameters for URL generation');
  }
};

export const useDriverService = () => {
  const { preferredLanguage } = usePreferredLanguageV2();
  const [appHeaderConfig] = useAppHeader();
  const { selectedCountry } = appHeaderConfig;
  const { data: userData } = useUserMetadata();
  const { state } = useLocation();

  const get = (service: 'measure' | 'type' | 'category' | 'group', chainId = '') => {
    const {
      refetch,
      data = {
        content: [],
      },
    } = useQuery<TDriverResponse>({
      queryKey: [`driver/${service}`, chainId],
      queryFn: () => {
        const url = `${DRIVER_BASE_URL}/${service}?chainId=${chainId}`;

        return getApi({
          url,
          config: {
            headers: {
              preferredLanguage,
              country: selectedCountry,
            },
          },
        });
      },
    });
    return { refetch, data: data.content };
  };

  const put = () =>
    useMutation({
      mutationFn: (body: TUpdateDriverProgress) =>
        putApi({ url: `${DRIVER_BASE_URL}/progress`, body }),
    });

  const useScopeCheck = (): TUseScopeCheckResponse => {
    let result: TUseScopeCheckResponse;
    const getUserScopes = useAuthenticationService()?.getUserScopes();

    try {
      const scopes = getUserScopes.includes('LinkAdmin.JBPC.Write');
      if (!scopes) {
        result = { hasPermission: false, errorStatus: 403, isError: true };
      } else {
        result = { hasPermission: true, isError: false };
      }
    } catch (error) {
      console.error(error);
      result = { hasPermission: false, isError: true, errorStatus: error.status };
    }

    return result;
  };

  const getSpreeadsheetTemplate = ({ featureType }: IRequestFileDataProps) => {
    const url = urlAccordingProps({
      actionType: null,
      request: 'get',
      featureType,
    });

    const getFileData = async () => {
      const response = await post({
        url: url,
        config: {
          headers: {
            country: selectedCountry,
            requestTraceId: `${QUERY_KEY_TEMPLATE}_${featureType}`,
          },
        },
      });

      /* const linkExpirationTime = parseInt(response.data.linkExpirationTime, 10) * 60 * 1000

      queryClient.setQueryDefaults([QUERY_KEY_TEMPLATE], {
        refetchInterval: linkExpirationTime,
      }) */

      return response;
    };

    const response = useQuery<ISpreeadsheetRequestResponse>(
      [QUERY_KEY_TEMPLATE, featureType],
      getFileData
    );

    return response;
  };

  const postFileData = ({ actionType }: IRequestFileDataProps) => {
    const { mutate, isLoading, isError, reset } = useMutation({
      mutationFn: async (fileToProcess: File) => {
        const data = new FormData();
        data.append('file', fileToProcess);

        const url = urlAccordingProps({ actionType, request: 'post', selectedCountry });

        const response = await postFormData({
          url: url,
          config: {
            headers: {
              country: selectedCountry,
              requestTraceId: `${QUERY_KEY_UPLOAD}_${actionType}`,
            },
          },
          data,
        });

        return response;
      },
    });

    return { mutate, isLoading, isError, reset };
  };

  /* istanbul ignore next */
  const postIRSFileData = ({ actionType }: IRequestFileDataProps) => {
    const { mutate, isLoading, isError, reset } = useMutation({
      mutationFn: async (fileToProcess: File) => {
        const data = new FormData();
        data.append('file', fileToProcess);

        const url = `api/link-commercial-kpis-service/spreadsheet/upload`;

        const response = await postFormData({
          url: url,
          config: {
            headers: {
              country: selectedCountry,
              requestTraceId: `${QUERY_KEY_UPLOAD}_${actionType}`,
              feature: `${FeatureType.IRS}`,
              language: `${userData.profile.preferredLanguage}`,
              userId: `${userData.userId}`,
              vendorId: `${userData.vendors[0].id}`,
            },
          },
          data,
        });
        return response;
      },
    });

    return { mutate, isLoading, isError, reset };
  };

  /* istanbul ignore next */
  const getProcessingStatus = (processId: string, enable: boolean) => {
    const { refetch, data } = useQuery({
      queryKey: ['processingStatus', processId, enable],

      queryFn: async () => {
        const url = `api/link-commercial-kpis-service/spreadsheet?processId=${processId}`;

        const response = await getApi({
          url,
          config: {
            headers: {
              country: selectedCountry,
              requestTraceId: `${processId}`,
              feature: `${FeatureType.IRS}`,
              language: `${userData.profile.preferredLanguage}`,
              userId: `${userData.userId}`,
              vendorId: `${userData.vendors[0].id}`,
            },
          },
        });
        return response.spreadsheetContent || response.spreadsheetDetailContent;
      },

      refetchInterval: (data) =>
        data?.spreadsheetContent?.status !== 'processed' ||
        data?.spreadsheetDetailContent?.status !== 'processed'
          ? 10000
          : false,

      refetchOnWindowFocus: false,
      enabled: enable,
    });

    return { refetch, data: data };
  };

  return {
    get,
    put,
    useScopeCheck,
    getSpreeadsheetTemplate,
    postFileData,
    postIRSFileData,
    getProcessingStatus,
  };
};
