import ReactApexChart, { Props } from 'react-apexcharts';
import { Heading } from '@hexa-ui/components';
import { chartOptions } from './chartOptions';
import { ILeverDonutProps } from './LeverDonut.props';

export const LeverDonut = ({
  title,
  data,
  percentage,
  chartLabels,
  sliceColors,
}: ILeverDonutProps): JSX.Element => {
  const ApexChart = ReactApexChart as unknown as React.FunctionComponent<Props>;

  return (
    <>
      <Heading size="H4" alignment="left">
        {title}
      </Heading>
      <ApexChart
        options={chartOptions(data, percentage, chartLabels, sliceColors)}
        series={chartOptions(data, percentage, chartLabels, sliceColors).series}
        type="donut"
      />
    </>
  );
};
