export function filterObjectsByCountry(data, countryAcronym) {
  if (!data || !countryAcronym) {
    return [];
  } else {
    return data.filter(
      (obj) => obj.contract.driverWithProgressAmount[0].country.acronym === countryAcronym
    );
  }
}

export const getKpiList = (data: any, type: string) => {
  const list = [];
  if (data) {
    data?.forEach((item) =>
      item?.component[type]?.component?.map((kpiItem) => {
        const brands = item.contract.driverWithProgressAmount
          .filter((driver) => driver.id === kpiItem?.content?.kpiId)
          .map((driver) => driver?.brands)
          .join(', ');
        list.push({ ...kpiItem, brands });
      })
    );
  }
  return list;
};

export function hasAcceptedReward(data) {
  if (data.length !== 0) {
    const rewardsAccepted = data.flatMap((item) =>
      Object.values(item.rewardComponent.toggle.accepted)
    );
    const hasUnAccepted = rewardsAccepted.some((reward) => reward === true);
    if (hasUnAccepted) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

export const formatUseGetContractByChainIdData = (contentArray, country) => {
  const consolidatedData = {
    rewardCardDataResponse: {
      accepted: false,
      locale: '',
      progress: {
        progressValue: 0,
        lastProgressValue: 0,
        historyStatus: false,
      },
      revenue: {
        totalRewards: 0,
        rewardsToEarn: 0,
      },
      contractType: '',
      isAnyKpiStarted: false,
      allKpisAchieved: false,
    },
    kpiCardDataResponse: {
      accepted: false,
      amountToAchieve: 0,
      amountAchievedLastMonth: 0,
      amountAchieved: 0,
      kpiNotAchieved: 0,
      contractType: '',
      totalKpis: 0,
      isAnyKpiStarted: false,
      allKpisAchieved: false,
    },
    growthCardDataResponse: [],
    numericCardDataResponse: [],
    percentageCardDataResponse: [],
    irsCardDataResponse: [],
    arCardDataResponse: [],
    contractInfoDataResponse: {
      contracts: [],
      startDate: null,
      endDate: null,
      contractId: null,
      contractName: null,
      contractType: null,
      driverInfoData: [],
      chainId: null,
      accountId: null,
    },
    dateRangeListResponse: contentArray?.dateRangeList,
  };

  if (contentArray && Object.keys(contentArray).length > 0) {
    const countryName = country;

    const contentAccordingToCountry = filterObjectsByCountry(
      contentArray.ContractsAndComponents,
      countryName
    );

    const summaryAndRewardAccordingToCountry = contentArray.SummaryAndRewardComponents.filter(
      (obj) => obj.country === countryName
    );

    const contracts = summaryAndRewardAccordingToCountry.flatMap((item) => [
      {
        contractId: item.physicalNumber,
        feature: item.feature,
        startDate: item.startDate,
        endDate: item.endDate,
      },
    ]);

    const summaryAcceptance = summaryAndRewardAccordingToCountry?.some(
      (data) => data.summaryComponent.toggle.kpiAcceptance
    );

    const types = ['growth', 'numeric', 'percentage', 'irs', 'ar'];
    types.forEach((type) => {
      consolidatedData[type + 'CardDataResponse'] = getKpiList(contentAccordingToCountry, type);
    });

    consolidatedData.rewardCardDataResponse.locale = countryName;

    consolidatedData.rewardCardDataResponse.accepted = hasAcceptedReward(
      summaryAndRewardAccordingToCountry
    );

    const someKpiStarted = [];
    const allKpisAchieved = [];

    summaryAndRewardAccordingToCountry.forEach((data) => {
      const rewardContent = data.rewardComponent.content;
      const summaryContent = data.summaryComponent.content;

      someKpiStarted.push(data.rewardComponent.toggle.isStarted);
      allKpisAchieved.push(data.summaryComponent.content.kpiNotAchieved);

      consolidatedData.rewardCardDataResponse.progress.lastProgressValue +=
        rewardContent.rewardProgress.lastTotalRewardsAchieved;
      if (data.rewardComponent.toggle.hasHistoryStatus) {
        consolidatedData.rewardCardDataResponse.progress.historyStatus =
          data.rewardComponent.toggle.hasHistoryStatus;
      }
      consolidatedData.rewardCardDataResponse.revenue.totalRewards += rewardContent.totalRewards;
      consolidatedData.rewardCardDataResponse.revenue.rewardsToEarn += rewardContent.rewardsToEarn;

      consolidatedData.kpiCardDataResponse.amountToAchieve +=
        summaryContent.totalOfKpis - summaryContent.kpiAchieved;
      consolidatedData.kpiCardDataResponse.amountAchievedLastMonth +=
        summaryContent.kpiAchievedLastMonth;
      consolidatedData.kpiCardDataResponse.amountAchieved += summaryContent.kpiAchieved;
      consolidatedData.kpiCardDataResponse.kpiNotAchieved += summaryContent.kpiNotAchieved;
      consolidatedData.kpiCardDataResponse.totalKpis += summaryContent.totalOfKpis;

      const endDate = new Date(data.endDate);
      if (
        !consolidatedData.contractInfoDataResponse.endDate ||
        endDate < new Date(consolidatedData.contractInfoDataResponse.endDate)
      ) {
        consolidatedData.contractInfoDataResponse.contracts = contracts;
        consolidatedData.contractInfoDataResponse.startDate = data.startDate;
        consolidatedData.contractInfoDataResponse.endDate = data.endDate;
        consolidatedData.contractInfoDataResponse.contractId = data.physicalNumber;
        consolidatedData.contractInfoDataResponse.contractName = data.chainName;
        consolidatedData.contractInfoDataResponse.accountId = data.accountId;

        consolidatedData.contractInfoDataResponse.contractType = data.feature;
        consolidatedData.kpiCardDataResponse.contractType = data.feature;
        consolidatedData.rewardCardDataResponse.contractType = data.feature;
        consolidatedData.contractInfoDataResponse.chainId = data.chainId;
      }
      if (data.rewardComponent.content.feature === 'ESCALAS') {
        consolidatedData.rewardCardDataResponse.progress.progressValue +=
          data.summaryComponent.content.totalRewardEarned;
      } else {
        consolidatedData.rewardCardDataResponse.progress.progressValue +=
          data.rewardComponent.content.rewardProgress.rewardsValueAchieved;
      }
    });

    contentAccordingToCountry.forEach((data) => {
      consolidatedData.contractInfoDataResponse.driverInfoData.push(
        ...data.contract.driverWithProgressAmount
      );
    });
    consolidatedData.kpiCardDataResponse.accepted = summaryAcceptance;

    // any kpi started for reward Card
    consolidatedData.rewardCardDataResponse.isAnyKpiStarted = someKpiStarted.some((obj) => {
      return obj ? Object.values(obj).includes(true) : false;
    });
    // any kpi started for kpi card
    consolidatedData.kpiCardDataResponse.isAnyKpiStarted = someKpiStarted.some((obj) => {
      return obj ? Object.values(obj).includes(true) : false;
    });
    // all kpis achieved for kpi Card
    consolidatedData.kpiCardDataResponse.allKpisAchieved = allKpisAchieved.every(
      (number) => number === 0
    );

    // all kpis achieved for reward Card
    consolidatedData.rewardCardDataResponse.allKpisAchieved = allKpisAchieved.every(
      (number) => number === 0
    );
  }
  return consolidatedData;
};
